import { LMS_RESIZED } from "./ui";
export const LMS_SIDEBAR_OPEN = 'LMS_SIDEBAR_OPEN';
export const LMS_SIDEBAR_CLOSE = 'LMS_SIDEBAR_CLOSE';

export const sidebar = (sidebar = {
  open: false,
  docked: false,
}, action) => {
  if (action.type === LMS_RESIZED) {
    if (action.md) {
      if (!sidebar.open || !sidebar.docked) {
        return { open: true, docked: true };
      }
    } else {
      if (sidebar.open || sidebar.docked) {
        return { open: false, docked: false };
      }
    }
  }

  switch(action.type) {
    case LMS_SIDEBAR_OPEN: return {...sidebar, open: true}; 
    case LMS_SIDEBAR_CLOSE: return { ...sidebar, open: false }; 
  }

  return sidebar;
}

export default sidebar;