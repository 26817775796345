import { LMS_NOTIFICATION_SHOW } from '../reducers/notifications';
import { LMS_NOTIFICATION_HIDE } from '../reducers/notifications';

export const notifications = (store) => {
  return next => action => {
    const res = next(action);
    
    if(action.type  === LMS_NOTIFICATION_SHOW && action.item.duration) {
      setTimeout(() => {
        store.dispatch({type: LMS_NOTIFICATION_HIDE, item: action.item});
      }, action.item.duration);
    }
  
    return res;
  }
}

export default notifications;