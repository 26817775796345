import React from 'react';

export const closeModal = ({id}) => ({
  type: 'LMS_CLOSE_MODAL', 
  item: {
    id
  }
});

export const infoModal = ({id = 2, title, message, html = false, label = 'Zamknij', onClose = () => {}}) => dispatch => dispatch({
  type: 'LMS_OPEN_MODAL',
  item: {
    id,
    html,
    title,
    close: () => dispatch(closeModal({ id })),
    content: <div className="text-center py-4">{ message }</div>,
    footer: [
      { label: label, onClick: () => onClose(), color: 'link' },
    ]
  }
});

export const confirmModal = ({id = 1, title, question, html = false, label = 'Tak', onClose = () => {}, onConfirm = () => {}}) => dispatch => dispatch({
  type: 'LMS_OPEN_MODAL',
  item: {
    id,
    html,
    title,
    close: () => dispatch(closeModal({ id })),
    content: html ? question : <div className="text-center py-4">{ question }</div>,
    footer: [
      { label: label, onClick: () => onConfirm(), color: 'danger' },
      { label: 'Anuluj', onClick: () => onClose(), color: 'link'}
    ]
  }
});

export const customModal = ({ id = 3, title, body, size="default", close }) => (dispatch) => dispatch({
  type: 'LMS_OPEN_MODAL',
  item: { 
    id, 
    body, 
    size,
    title,
    close: close || (() => dispatch(closeModal({ id })))
  }
});

export const confirm = ({ title, question }) => dispatch => {
  return new Promise((res) => {
    dispatch(confirmModal({ title, question, onConfirm: res }));
  });
}