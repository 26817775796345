export const LMS_AUTHENTICATE_INIT = 'LMS_AUTHENTICATE_INIT';
export const LMS_AUTHENTICATE_LOGOUT = 'LMS_AUTHENTICATE_LOGOUT';
export const LMS_AUTHENTICATE_SUCCESS = 'LMS_AUTHENTICATE_DONE'

export default function (state = { token: null }, action = {}) {
  switch (action.type) {
    case LMS_AUTHENTICATE_SUCCESS:
      return { ...state, token: action.token, wait: null };

    case LMS_AUTHENTICATE_LOGOUT:
      return { ...state, token: null, wait: null };

    case LMS_AUTHENTICATE_INIT:
      return { ...state, wait: action.wait };
  }

  return state;
}