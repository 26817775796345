import 'fetch-ie8';
import Client from 'jayson/lib/client/browser';

let clientToken = null;
let clientEndpoint = null;

let client = Client((request, callback) => {
  const options = {
    method: 'POST',
    body: request,
    headers: {
      'Content-Type': 'application/json',
    }
  };

  fetch(`${clientEndpoint}?token=${clientToken}`, options)
    .then(function (res) { return res.text(); })
    .then(function (res) { callback(null, res); })
    .catch(function (err) { console.log(err); callback(err); });
});

export const setup = ({ endpoint, token }) => {
  token !== undefined && (clientToken = token);
  endpoint !== undefined && (clientEndpoint = endpoint);
};

export const request = (method, params) => {
  return new Promise((res, rej) => {
    client.request(method, params, (err, error, result) => {
      err && rej(err) || error && rej(error) || res(result);
    });
  })
}

window.clear = () => {
  setup({ token: 'none' });
}