import React from 'react';
import { connect } from 'react-redux';

let idx = 0;

const CLEAR = '@@redux-async-loader/CLEAR';
const LOADED = '@@redux-async-loader/LOADED';
const PENDING = '@@redux-asynx-loader/PENDING';
const FAILURE = '@@redux-async-loader/FAILURE';

const RELOAD = '@@redux-async-loader/RELOAD';
const REFRESH = '@@redux-async-loader/REFERSH';

/** 
 * Call request helper 
 */
const call = (cfg, args, props, dispatch, type = PENDING) => {
    dispatch({ key: cfg.key, type });

    const req = cfg.caller(props, args || {}, dispatch);
          req.catch((err) => cfg.onerror(err, dispatch));
          req.then(
            (res) => dispatch({ type: LOADED, key: cfg.key, data: res }), 
            (err) => dispatch({ type: FAILURE, key: cfg.key, error: err })
          );
    
    if(cfg.timer) {
      clearTimeout(cfg.timer);
    }
    
    if(cfg.refresh && !cfg.aborted) {
      req.then(() => cfg.timer = setTimeout(() => call(cfg, args, props, dispatch, REFRESH), cfg.refresh));
      req.catch(() => cfg.timer = setTimeout(() => call(cfg, args, props, dispatch, REFRESH), cfg.refresh));
    }
}

/**
 * Reducer function should be used during creating store with combineReducer
 */
export function reducer(state = {}, action) {
  if(action.type === REFRESH) {
    return state;
  }

  const result = { ...state };

  switch(action.type) {
    case PENDING:
      result[action.key] = { data: null, errors: null, pending: true, reload: false }; break;
    case RELOAD:
      if(result[action.key]) {
        result[action.key] = { data: result[action.key].data, errors: result[action.key].errors, pending: result[action.key].pending, reload: true }; break;
      }
    case LOADED:
      if(result[action.key]) {
        result[action.key] = { data: action.data, errors: null, pending: false, reload: false }; break;
      }
    case FAILURE:
      if(result[action.key]) {
        result[action.key] = { data: null, errors: action.error, pending: false, reload: false }; break;
      }
    case CLEAR: 
      result[action.key] = null; break;
  }
  
  return result;
}

/**
 * onerror - default logout on -32401 unauthorize error
 */
export function load(Target, {namespace = '', autoload = true, onerror = null, props = () => {}, fetch = {}, watch = {}, defaults = {}, depends=null}) {
  const config = [];
  const defaultData = {};

  if(onerror === null) {
    onerror = (err, dispatch) => {
      if(err.code === -32401) {
        dispatch({type: 'LMS_AUTHENTICATE_LOGOUT'});
      }
    };
  }

  Object.keys(fetch).forEach((name) => {
    config.push({name, onerror, caller: fetch[name], key:  '@redux-loader:' + (idx++), aborted: false });
  });

  Object.keys(watch).forEach((name) => {
    config.push({name, onerror, caller: watch[name], key: '@redux-watcher:' + (idx++), refresh: 5000, aborted: false });
  });
  
  config.forEach((cfg) => {
    defaultData[cfg.name] = { data: defaults[cfg.name] || null, errors: null, pending: autoload ? true : undefined };
  });

  const mapStateToProps = (state, ownProps) => {
    let data = {};
    let result = props(ownProps) || {};
    let store = state.asyncDataLoader || {};

    config.forEach((cfg) => {
      data[cfg.name] = store[cfg.key] || defaultData[cfg.name];
    });

    /**
     * This is small hack form react-form because there we need pass data as object initialValues not initialValues.data
     */
    if(config.length === 1 && config[0].name === 'initialValues') {
      data = {
        initialValues: data.initialValues.data,
        pending: data.initialValues.pending,
        errors: data.initialValues.errors,
      };
    }

    if(namespace) {
      result[namespace] = data;
    } else {
      result = { ...result, ...data };
    }

    return result;
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      loader: {
        init: () => {
          config.forEach((cfg) => {
            cfg.aborted = false;
          });
        },

        load: (item, args) => {
          if(typeof item === 'string') {
            config.filter((cfg) => cfg.name === item).forEach((cfg) => { call(cfg, args, ownProps, dispatch) });      
          } else {
            config.forEach((cfg) => { call(cfg, item, ownProps, dispatch) });
          }
        },

        reload: (item, args) => {
          if(typeof item === 'string') {
            config.filter((cfg) => cfg.name === item).forEach((cfg) => { call(cfg, args, ownProps, dispatch, RELOAD) });      
          } else {
            config.forEach((cfg) => { call(cfg, item, ownProps, dispatch, RELOAD) });
          }
        },

        unload: () => {
          config.forEach((cfg) => {
            dispatch({key: cfg.key, type: CLEAR });
        
            if(cfg.aborted === false) {
              cfg.aborted = true;
            }

            if(cfg.timer) { 
              clearTimeout(cfg.timer);
            }
          });
        },

        getItems: () => config.map(item => item.name)
      },

      dispatch: (action) => dispatch(action)
    }; 
  };

  class LmsLoader extends React.Component {
    componentDidMount() {
      this.props.loader.init();

      if(autoload) {
        this.props.loader.load();
      }
    }
    
    componentWillUnmount() {
      this.props.loader.unload();
    }
    
    componentDidUpdate(prevProps) {
      if(depends && depends.length) {
        for(let i=0, l=depends.length; i<l; i++) {
          if(this.props[depends[i]] !== prevProps[depends[i]]) {
            return this.props.loader.reload();
          }
        }
      }
    }

    render() {
      return <Target { ...this.props } />;
    }
  }
  
  return connect(mapStateToProps, mapDispatchToProps)(LmsLoader);
}
