export const LMS_OPEN_MODAL = 'LMS_OPEN_MODAL';
export const LMS_CLOSE_MODAL = 'LMS_CLOSE_MODAL';
export const LMS_CLEAR_MODALS = 'LMS_CLEAR_MODALS';

export default function (state = [], action = {}) {
  if (action.type === LMS_OPEN_MODAL) {
    return [...state].filter(item => item.id !== action.item.id).concat(action.item);
  }
  if (action.type == LMS_CLOSE_MODAL) {
    return [...state].filter(item => item.id !== action.item.id);
  }
  if (action.type == LMS_CLEAR_MODALS) {
    return [];
  }

  return state;
}
