import React from 'react'
import LmsPreloader from '../components/LmsPreloader';

import { call } from '../lib/jsonrpc';
import { useData } from '../hooks/useData';
import { useSelector } from 'react-redux';

const LmsApplication = React.lazy(() => import('./LmsApplication'));
const LmsAuthenticate = React.lazy(() => import('./LmsAuthenticate'));

const getUser = (res) => {
  if (res.user) {
    return { ...res.user, hasPermission: (role) => role === 'any' || res.user.scopes.indexOf(role) >= 0 };
  }

  return null;
}

const Lms = () => {
  const token = useSelector(state => state.authentication.token);

  const [ip] = useData(() => call('userIp'), {}, false);
  const [user] = useData(() => call('userMe').then(getUser), { token }, false);

  return (
    <React.Suspense fallback={<LmsPreloader />}>
      {(user.state === 'error') && <LmsAuthenticate />}
      {(user.state === 'pending' || user.state == 'reloading' && user.data === null) && <LmsPreloader />}
      {(user.state === 'loaded' || user.state == 'reloading' && user.data !== null) && <LmsApplication user={user.data} />}
    </React.Suspense>
  );
}

export default Lms;