import {
  LMS_RESOURCE_ATTACH,
  LMS_RESOURCE_DETACH,
  LMS_RESOURCE_CLEAR,
  LMS_RESOURCE_RELOAD,
  LMS_RESOURCE_SUCCESS,
  LMS_RESOURCE_FAILURE,
  LMS_RESOURCE_ACTIONS,
  LMS_RESOURCE_LOADING,
  LMS_RESOURCE_RELOADING,
} from '../reducers/resources'; 

import { useApi } from '../hooks/useApi';
import { getKey } from '../reducers/resources'; 

export const service = store => next => action => {
  next(action);

  if(LMS_RESOURCE_ACTIONS.indexOf(action.type) >= 0) {
    let key = action.key || getKey(action.method, action.params);
    let data = store.getState().resources[key];
    let { call } = useApi(store.dispatch);

    const success = (data) => store.dispatch({ type: LMS_RESOURCE_SUCCESS, key, data, errors: null });
    const failure = (errors) => store.dispatch({ type: LMS_RESOURCE_FAILURE, key, data: null, errors });
    
    switch(action.type) {
      case LMS_RESOURCE_ATTACH:
        if(data.instances === 1) {
          store.dispatch({ type: LMS_RESOURCE_LOADING, key });
          store.dispatch(() => call(action.method, action.params)).then(success, failure);
        }
        break;

      case LMS_RESOURCE_DETACH:
        if(data && data.instances === 0) {
          store.dispatch({ type: LMS_RESOURCE_CLEAR, key });
        }
        break;

      case LMS_RESOURCE_RELOAD:
        if(data === undefined) {
          return false;
        }

        store.dispatch({ type: LMS_RESOURCE_RELOADING, key });
        store.dispatch(() => call(data.meta.method, data.meta.params)).then(success, failure);
        break;
    }
  }
};

export default service;
