export const LMS_RESOURCE_ATTACH = '@@redux-lms/RESOURCE_ATTACH';
export const LMS_RESOURCE_DETACH = '@@redux-lms/RESOURCE_DETACH';
export const LMS_RESOURCE_LOAD = '@@redux-lms/RESOURCE_LOAD';
export const LMS_RESOURCE_CLEAR = '@@redux-lms/RESOURCE_CLEAR';
export const LMS_RESOURCE_RELOAD = '@@redux-lms/RESOURCE_RELOAD';
export const LMS_RESOURCE_SUCCESS = '@@redux-lms/RESOURCE_SUCCESS';
export const LMS_RESOURCE_FAILURE = '@@redux-lms/RESOURCE_FAILURE';
export const LMS_RESOURCE_LOADING = '@@redux-lms/RESOURCE_LOADING';
export const LMS_RESOURCE_RELOADING = '@@redux-lms/RESOURCE_RELOADING';

export const LMS_RESOURCE_ACTIONS = [
  LMS_RESOURCE_ATTACH,
  LMS_RESOURCE_DETACH,
  LMS_RESOURCE_LOAD,
  LMS_RESOURCE_CLEAR,
  LMS_RESOURCE_RELOAD,
  LMS_RESOURCE_SUCCESS,
  LMS_RESOURCE_FAILURE,
  LMS_RESOURCE_LOADING,
  LMS_RESOURCE_RELOADING,
];

export const getKey = (method, params) => {
  return `${method}(${JSON.stringify(params)})`;
}

export default function(state = {}, action = {}) {
  if(LMS_RESOURCE_ACTIONS.indexOf(action.type) < 0) {
    return state;
  }

  let key = action.key || getKey(action.method, action.params);
  let data = state[key] || null;

  if(data === null && action.type === LMS_RESOURCE_ATTACH) {
    data = {
      data: null, 
      errors: null, 
      pending: undefined, 
      reloading: false, 
      instances: 0, 
      meta: {
        key, 
        params: action.params,
        method: action.method
      }
    };
  }

  switch(action.type) {
    case LMS_RESOURCE_CLEAR: data = null; break;
    case LMS_RESOURCE_ATTACH: data.instances++; break;
    case LMS_RESOURCE_DETACH: data.instances--; break;
    case LMS_RESOURCE_LOADING: data.pending = true; break;
    case LMS_RESOURCE_RELOADING: data.reloading = true; break;
    case LMS_RESOURCE_SUCCESS: 
    case LMS_RESOURCE_FAILURE:
      if(data) {
        data.pending = false;
        data.reloading = false;
        data.data = action.data;
        data.errors = action.errors;
      }
      break;
  }
  
  state[key] = data && data.instances > 0 ? { ...data } : undefined;

  return state;
}