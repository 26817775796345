import modals from './modals';
import sidebar from './sidebar';
import resources from './resources';
import authentication from './authentication';
import { reducer as form } from 'redux-form';
import { reducer as asyncDataLoader } from '../lib/async-data-loader';
import notifications  from './notifications';
import cmtsSpectrum from './cmts-spectrum';

export let reducers = {
  form,
  modals,
  sidebar,
  resources,
  authentication,
  asyncDataLoader,
  notifications,
  cmtsSpectrum, 
};