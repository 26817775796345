import thunk from 'redux-thunk';
import api from './api';
import modals from './modals';
import sidebar from './sidebar';
import spectrum from './cmts-spectrum';
import resources from './resources';
import notifications from './notifications';
import authentication from './authentication';

export const services = [api, thunk, authentication, modals, sidebar, notifications, spectrum, resources];
