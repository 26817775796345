import { oids } from "../lib/oids";
import { call } from "../lib/jsonrpc"

export const networkDeviceCiscoSpectrumEntryInsert = (id, entryParam) => {
  return call('networkDeviceSnmpWalk', {
    id, 
    oids: [oids.ccsSpectrumRequestStatus]
  }).then(res => {
    const idx = Object.keys(res).map(oid => +oid.split('.').pop());
          idx.sort((a, b) => a-b);
    const last = (idx.pop() || 0) + 1;

    const items = [
      { oid: oids.ccsSpectrumRequestStatus + '.' + last, value: 5, type: 2},
      { oid: oids.ccsSpectrumRequestLowFreq + '.' + last, value: +entryParam.from, type: 2},
      { oid: oids.ccsSpectrumRequestUpperFreq + '.' + last, value: +entryParam.to, type: 2},
      { oid: oids.ccsSpectrumRequestResolution + '.' + last, value: +entryParam.resolution, type: 2},
      { oid: oids.ccsSpectrumRequestStatus + '.' + last, value: 1  , type: 2},
    ]

    if(entryParam.interface) {
      items.splice(1, 0, { oid: oids.ccsSpectrumRequestIfIndex + '.' + last, value: +entryParam.interface, type: 2 });
    }
    if(entryParam.cm) {
      items.splice(1, 0, { oid: oids.ccsSpectrumRequestMacAddr + '.' + last, value: entryParam.cm.replace(/:/g, ''), type: 164 });
    }

    return call('networkDeviceSnmpSetMulti', {
      id,
      items
    });
  });
}

export default networkDeviceCiscoSpectrumEntryInsert;