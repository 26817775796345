import { login } from "../actions/authentication";
import { LMS_AUTHENTICATE_INIT } from "../reducers/authentication";

const api = store => next => action => {
  if(typeof action === 'function' && action.api) {
    const state = store.getState();
    const dispatch = store.dispatch;

    if (state.authentication.wait) {
      return new Promise((resolve, reject) => {
        state.authentication.wait.then(() => action(dispatch).then(resolve, reject));
      });
    }

    return new Promise((resolve, reject) => {
      action(dispatch).then(
        (res) => resolve(res),
        (err) => {
          if (err.code === -32401) {
            if (store.getState().authentication.wait) {
              store.getState().authentication.wait.then(() => action(dispatch).then(resolve, reject));
            } else {
              dispatch({ 
                type: LMS_AUTHENTICATE_INIT,
                wait: dispatch(login()).then(
                  () => action(dispatch).then(resolve, reject),
                  () => reject(err) 
                )
              });
            }
          } else {
            reject(err);
          }
        }
      );
    });
  }

  return next(action);
}

export default api;