import { set as cookie } from 'js-cookie';
import { setup } from '../lib/jsonrpc-client';
import { LMS_AUTHENTICATE_SUCCESS, LMS_AUTHENTICATE_LOGOUT } from '../reducers/authentication';

const service = () => next => action => {
  if(action.type === LMS_AUTHENTICATE_SUCCESS || action.type == LMS_AUTHENTICATE_LOGOUT) {
    const token = action.token || 'anonymous';
    
    setup({ token });
    cookie('token', token , { domain: '.tvk-wolczyn.pl' });
  }
  
  return next(action);
}

export default service;