import { request } from './jsonrpc-client';
import { SubmissionError } from 'redux-form'

let buffer = [];
let pending = false;

const queue = (method, args) => {
  buffer.push({ method, args });

  return new Promise((res, rej) => {

  });
}

export const call = (method, args) => {
  //console.log('call', queue(method, args));

  return request(method, args).catch(err => {
    if (err.code !== -32401) {
      console.error(method, args, err); 
    }
    
    throw err;
  });
};

export const submit = (method, args) => {
  return request(method, args).catch(err => {
    console.error(method, args, err);

    if(err.code === -32401) {
      throw err;
    } else {
      throw new SubmissionError(err.data && err.data.errors || { _error: err.message, code: err.code })
    }
  });
};