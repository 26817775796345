import { call } from '../lib/jsonrpc';
import { oids } from '../lib/oids';

export const networkDeviceCiscoScpectrumTable = ({ id }) => {
  return call('networkDeviceSnmpWalk', { id, oids: [ oids.ccsSpectrumRequestTable, oids.ifDescr ], hex: true }).then(data => {
    const list = [];
  
    Object.keys(data).filter(key => key.indexOf(oids.ccsSpectrumRequestIfIndex) === 0).forEach(key => {
      const idx = key.split('.').slice(-1).join('');
      const item = {
        ccsSpectrumRequestIndex: idx,
        ccsSpectrumRequestIfIndex: data[oids.ccsSpectrumRequestIfIndex + '.' + idx].value,
        ccsSpectrumRequestMacAddr: data[oids.ccsSpectrumRequestMacAddr + '.' + idx].hex.match(/[0-9a-f]{2}/g).join(':'),
        ccsSpectrumRequestLowFreq: data[oids.ccsSpectrumRequestLowFreq + '.' + idx].value,
        ccsSpectrumRequestUpperFreq: data[oids.ccsSpectrumRequestUpperFreq + '.' + idx].value,
        ccsSpectrumRequestResolution: data[oids.ccsSpectrumRequestResolution + '.' + idx].value,
        ccsSpectrumRequestOperation: data[oids.ccsSpectrumRequestOperation + '.' + idx].value,
        ccsSpectrumRequestOperState: data[oids.ccsSpectrumRequestOperState + '.' + idx].value,
        ccsSpectrumRequestStartTime: data[oids.ccsSpectrumRequestStartTime + '.' + idx].value,
        ccsSpectrumRequestStoppedTime: data[oids.ccsSpectrumRequestStoppedTime + '.' + idx].value,
        ccsSpectrumRequestStatus: data[oids.ccsSpectrumRequestStatus + '.' + idx].value,
      };

      if(data[oids.ccsSpectrumRequestIfIndex + '.' + idx].value) {
        item.ccsSpectrumRequestIfName = data[oids.ifDescr + '.' + data[oids.ccsSpectrumRequestIfIndex + '.' + idx].value].value;
      } 

      list.push(item);
    });
    
    return list;
  });
}

export default networkDeviceCiscoScpectrumTable;