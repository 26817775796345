export const CLEAN_SPECTRUM_TABLE = '@reduc/lms-cmts-spectrum-clean';
export const FETCH_SPECTRUM_TABLE = '@reduc/lms-cmts-spectrum-fetch';
export const FETCH_SPECTRUM_TABLE_SUCCESS = '@reduc/lms-cmts-spectrum-fetch-success';
export const FETCH_SPECTRUM_TABLE_FAILURE = '@reduc/lms-cmts-spectrum-fetch-failure';
export const INSERT_SPECTRUM_ENTRY = '@redux/lms-cmts-spectrum-entry-insert';
export const REMOVE_SPECTRUM_ENTRY = '@redux/lms-cmts-spectrum-entry-remove';
export const START_SPECTRUM_ENTRY = '@redux/lms-cmts-spectrum-entry-start';
export const STOP_SPECTRUM_ENTRY = '@redux/lms-cmts-spectrum-entry-stop';

const reducer = (state = {}, action) => {
  switch(action.type) {
    case FETCH_SPECTRUM_TABLE:
      if(state[action.id]) {
        return {...state, [action.id]: {...state[action.id], state: 'reloading' }};
      } else {
        return {...state, [action.id]: { data: [], state: 'pending' }};
      }

    case FETCH_SPECTRUM_TABLE_SUCCESS:
      return {...state, [action.id]: { data: action.data, state: 'loaded' }};
    case FETCH_SPECTRUM_TABLE_FAILURE:
      return {...state, [action.id]: { data: [], state: 'error', error: action.error }};
    case CLEAN_SPECTRUM_TABLE:
      return {...state, [action.id]: undefined};
  }

  return state;
}

export default reducer;