import { call } from '../lib/jsonrpc';
import { submit } from '../lib/jsonrpc';
import { useDispatch } from "react-redux"

export const useApi = (dispatch) => {
  dispatch = dispatch || useDispatch();

  const callWrapper = function (method, params) {
    const func = () => typeof method === 'function' ? method() : call(method, params);
          func.api = true;

    return dispatch(func);
  }

  const submitWrapper = function (method, params) {
    const func = () => submit(method, params);
          func.api = true;

    return dispatch(func);
  }

  return { call: callWrapper, submit: submitWrapper };
}