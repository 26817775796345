export const oids = {
  ifType: '.1.3.6.1.2.1.2.2.1.3',
  ifDescr: '.1.3.6.1.2.1.2.2.1.2',
  sysUpTime: '.1.3.6.1.2.1.1.3.0',

  ifInOctets: '.1.3.6.1.2.1.2.2.1.10',
  ifInUcastPkts: '.1.3.6.1.2.1.2.2.1.11',
  ifInNUcastPkts: '.1.3.6.1.2.1.2.2.1.12',
  ifInDiscards: '.1.3.6.1.2.1.2.2.1.13',
  ifInErrors: '.1.3.6.1.2.1.2.2.1.14',
  ifOutOctets: '.1.3.6.1.2.1.2.2.1.16',
  ifOutUcastPkts: '.1.3.6.1.2.1.2.2.1.17',
  ifOutNUcastPkts: '.1.3.6.1.2.1.2.2.1.18',
  ifOutDiscards: '.1.3.6.1.2.1.2.2.1.19',
  ifOutErrors: '.1.3.6.1.2.1.2.2.1.20',


  ifHCInOctets: '.1.3.6.1.2.1.31.1.1.1.6',
  ifHCOutOctets: '.1.3.6.1.2.1.31.1.1.1.10',

  ccsSpectrumRequestEntry: '.1.3.6.1.4.1.9.9.114.1.2.1.1',
  ccsSpectrumRequestIfIndex: '.1.3.6.1.4.1.9.9.114.1.2.1.1.2',
  ccsSpectrumRequestMacAddr: '.1.3.6.1.4.1.9.9.114.1.2.1.1.3',
  ccsSpectrumRequestLowFreq: '.1.3.6.1.4.1.9.9.114.1.2.1.1.4',
  ccsSpectrumRequestUpperFreq: '.1.3.6.1.4.1.9.9.114.1.2.1.1.5',
  ccsSpectrumRequestResolution: '.1.3.6.1.4.1.9.9.114.1.2.1.1.6',
  ccsSpectrumRequestOperation: '.1.3.6.1.4.1.9.9.114.1.2.1.1.7',
  ccsSpectrumRequestOperState: '.1.3.6.1.4.1.9.9.114.1.2.1.1.8',
  ccsSpectrumRequestStartTime: '.1.3.6.1.4.1.9.9.114.1.2.1.1.9',
  ccsSpectrumRequestStoppedTime: '.1.3.6.1.4.1.9.9.114.1.2.1.1.10',
  ccsSpectrumRequestStatus: '.1.3.6.1.4.1.9.9.114.1.2.1.1.11',
  ccsSpectrumRequestTable: '.1.3.6.1.4.1.9.9.114.1.2.1',
  ccsSpectrumDataTable: '.1.3.6.1.4.1.9.9.114.1.2.2.1.2',

  docsDevEvCounts: '.1.3.6.1.2.1.69.1.5.8.1.4',
  docsDevDateTime: '.1.3.6.1.2.1.69.1.5.8.1.3',
  docsDevEvText: '.1.3.6.1.2.1.69.1.5.8.1.7',

  cpmCPUTotal5sec: '.1.3.6.1.4.1.9.9.109.1.1.1.1.3.1',
  cpmCPUTotal1min: '.1.3.6.1.4.1.9.9.109.1.1.1.1.4.1',
  cpmCPUTotal5min: '.1.3.6.1.4.1.9.9.109.1.1.1.1.5.1',
  ciscoEnvMonTemperatureStatusValue: '.1.3.6.1.4.1.9.9.13.1.3.1.3',
};

export default oids;