import { LMS_POPSTATE } from "../reducers/history";
import { LMS_OPEN_MODAL, LMS_CLOSE_MODAL, LMS_CLEAR_MODALS } from "../reducers/modals";

const type = 'lms-modal';
const hist = window.history;

if(hist.state && hist.state.type === type) {
  hist.go(-1);
}

export const modals = store => next => action => {
  if (action.type === LMS_POPSTATE && store.getState().modals.length) {
    store.dispatch({ type: LMS_CLEAR_MODALS });
  }

  if (action.type === LMS_CLOSE_MODAL && hist.state && hist.state.type === type) {
    hist.go(-1);
  }

  if (action.type === LMS_CLEAR_MODALS && hist.state && hist.state.stype === type) {
    hist.go(-1);
  }

  if (action.type === LMS_OPEN_MODAL && (!hist.state || hist.state.type !== type)) {
    hist.pushState({ type, modalId: action.item.id }, null, null);
  }

  return next(action);
}

export default modals;