import { useState, useEffect } from 'react';
import { useApi } from './useApi';
import PCancelable from 'p-cancelable';

export const useData = (fetch, params, wrapFetch = true) => {
  const { call } = useApi();
  const [data, setState] = useState({ data: null, errors: null, state: 'pending', pending: true, reloading: false });

  const reload = () => {
    if (data.state !== 'pending') {
      setState({ ...data, state: 'reloading', loading: true, reloading: true });
    }

    let request = (wrapFetch ? call(() => fetch(params)) : fetch(params));

    if((request instanceof PCancelable) === false) {
      const orig = request;

      request = new PCancelable((res, rej, onCancel) => {
        onCancel.shouldReject = false;
        orig.then(res, rej);
      });
    } 
    
    request.then(
      (res) => !request.isCanceled && setState({ data: res, state: 'loaded', errors: null, loading: false, reloading: false }),
      (err) => !request.isCanceled && setState({ data: null, state: 'error', errors: err, loading: false, reloading: false })
    );

    return request;
  }

  useEffect(() => {
    const request = reload();

    return () => {
      request.cancel();
    }
  }, Object.keys(params).map(key => typeof params[key] == 'object' ? JSON.stringify(params[key]) : params[key]));

  return [data, reload];
};

export default useData;