import { 
  FETCH_SPECTRUM_TABLE, 
  CLEAN_SPECTRUM_TABLE, 
  INSERT_SPECTRUM_ENTRY, 
  REMOVE_SPECTRUM_ENTRY, 
  START_SPECTRUM_ENTRY,
  STOP_SPECTRUM_ENTRY
} from "../reducers/cmts-spectrum";

export const fetchSpectrumTable = (id) => ({
  id,
  type: FETCH_SPECTRUM_TABLE,
})

export const cleanSpectrumTable = (id) => ({
  id,
  type: CLEAN_SPECTRUM_TABLE,
});

export const insertSpectrumEntry = (id, entryAttrs) => ({
  id,
  entryAttrs,
  type: INSERT_SPECTRUM_ENTRY
});

export const removeSpectrumEntry = (id, entryId) => ({
  id,
  entryId,
  type: REMOVE_SPECTRUM_ENTRY
});

export const startSpectrumEntry = (id, entryId) => ({
  id, 
  entryId,
  type: START_SPECTRUM_ENTRY
});

export const stopSpectrumEntry = (id, entryId) => ({
  id, 
  entryId,
  type: STOP_SPECTRUM_ENTRY
});