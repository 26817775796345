export const LMS_NOTIFICATION_SHOW = '@@redux/lms-notification-show';
export const LMS_NOTIFICATION_HIDE = '@@redux/lms-notification-hide';

export const notifications = (state = [], action) => {
  switch(action.type) {
    case LMS_NOTIFICATION_SHOW: 
      return [...state, action.item];
    case LMS_NOTIFICATION_HIDE:
      return [...state].filter(item => item.id !== action.item.id);
  }

  return state;
}

export default notifications;