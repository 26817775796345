import React from 'react';
import { submit } from '../lib/jsonrpc';
import { customModal, closeModal } from "./modals"
import { LMS_AUTHENTICATE_SUCCESS, LMS_AUTHENTICATE_LOGOUT } from '../reducers/authentication';

const id = 'authentication';
const title = 'Logowanie...';
const LmsAuthenticationForm = React.lazy(() => import('../components/authentication/LmsAuthenticationForm'));

export const logout = () => ({
  type: LMS_AUTHENTICATE_LOGOUT,
});

export const authenticated = (token) => ({
  type: LMS_AUTHENTICATE_SUCCESS,
  token
});

export const login = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    const close = () => {
      dispatch(logout());
      dispatch(closeModal({ id }));
    };

    const body = () => <LmsAuthenticationForm
      onSubmit={(args) => submit('authenticate', args)}
      onSubmitSuccess={({ token }) => { 
        dispatch(authenticated(token));
        dispatch(closeModal({ id }));
        resolve();
      }}
      className="p-3 p-md-4"
    />

    dispatch(customModal({ id, title, close, body }));
  });
};