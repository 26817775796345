import React from 'react';

export default (props) => {
  let className = 'loader';
  
  if(props.size) {
    className += ' ' + (props.size || 'medium');
  }

  if(props.className) {
    className += ' ' + props.className;
  }

  if(props.inline) {
    return <div className="loader__inline"><div className={className}></div></div>
  }

  if(props.fill) {
    return <div className="loader-overlay">
      <div className="loader-holder" data-msg={props.message}><div className={className}/></div>
    </div>;
  }

  return (
    <div className="loader-wrapper">
      <div className={className}></div>
    </div>
  );
}
