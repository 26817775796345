import { LMS_POPSTATE } from "../reducers/history";
import { LMS_SIDEBAR_CLOSE, LMS_SIDEBAR_OPEN } from "../reducers/sidebar";

const type = 'lms-sidebar';
const hist = window.history;

if(hist.state && hist.state.type === type) {
  hist.go(-1);
}

export const modals = store => next => action => {
  const sidebar = store.getState().sidebar;

  if (action.type === LMS_POPSTATE && sidebar.open && sidebar.docked === false) {
    store.dispatch({ type: LMS_SIDEBAR_CLOSE });
  }

  if (action.type === LMS_SIDEBAR_CLOSE && hist.state && hist.state.type === type) {
    hist.go(-1);
  }

  if (action.type === LMS_SIDEBAR_OPEN && (!hist.state || hist.state.type !== type)) {
    hist.pushState({ type }, null, null);
  }

  return next(action);
}

export default modals;