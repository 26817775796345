import * as Cookie from 'js-cookie';

if (!Array.isArray) {
  Array.isArray = function (arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}

import React from 'react';
import ReactDOM from 'react-dom';
import Lms from './containers/Lms.js';

import { setup } from './lib/jsonrpc-client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

import { services } from './services';
import { reducers } from './reducers';
import { LMS_RESIZED } from './reducers/ui.js';
import { LMS_POPSTATE } from './reducers/history.js';

setup({ endpoint: process.env.API_RPC, token: Cookie.get('token') });

const mqlMD = window.matchMedia('(min-width: 769px)');
const mqlLG = window.matchMedia('(min-width: 992px)');
const mqlXL = window.matchMedia('(min-width: 1200px)');

const middlewares = [...services];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const state = { 
  sidebar: { open: !!mqlMD.matches, docked: !!mqlMD.matches },
  authentication: { token: Cookie.get('token') || 'anonymouse' } 
};

const store = createStore(combineReducers(reducers), state, composeEnhancers(applyMiddleware(...middlewares)));

const resized = () => {
  store.dispatch({ type: LMS_RESIZED, md: !!mqlMD.matches, lg: !!mqlLG.matches, xl: !!mqlXL.matches });
}

mqlMD.addListener(resized);
mqlLG.addListener(resized);
mqlXL.addListener(resized);

window.addEventListener('popstate', (e) => {
  store.dispatch({ type: LMS_POPSTATE, state: e.state });
});

/**
 * We dispatch resized at begin to be sure that all component can use proper data
 */
resized();

/**
 * Finaly we render appliction
 */
ReactDOM.render(<Provider store={store} ><Lms /></Provider>, document.getElementById('root'));