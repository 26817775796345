import { FETCH_SPECTRUM_TABLE, FETCH_SPECTRUM_TABLE_SUCCESS, FETCH_SPECTRUM_TABLE_FAILURE, INSERT_SPECTRUM_ENTRY, REMOVE_SPECTRUM_ENTRY, START_SPECTRUM_ENTRY, STOP_SPECTRUM_ENTRY } from "../reducers/cmts-spectrum";
import { fetchSpectrumTable } from "../actions/cmts-spectrum";
import { networkDeviceCiscoScpectrumTable } from "../rpc/networkDeviceCiscoScpectrumTable";
import { networkDeviceCiscoSpectrumEntryStop } from "../rpc/networkDeviceCiscoSpectrumEntryStop";
import { networkDeviceCiscoSpectrumEntryStart } from "../rpc/networkDeviceCiscoSpectrumEntryStart";
import { networkDeviceCiscoSpectrumEntryInsert } from "../rpc/networkDeviceCiscoSpectrumEntryInsert";
import { networkDeviceCiscoSpectrumEntryRemove } from "../rpc/networkDeviceCiscoSpectrumEntryRemove";

const service = store => next => action => {
  if(action.type === FETCH_SPECTRUM_TABLE) {
    networkDeviceCiscoScpectrumTable({ id: action.id }).then(
      (res) => store.dispatch({ type: FETCH_SPECTRUM_TABLE_SUCCESS, id: action.id, data: res }),
      (rej) => store.dispatch({ type: FETCH_SPECTRUM_TABLE_FAILURE, id: action.id, error: rej })
    );
  }

  if(action.type == INSERT_SPECTRUM_ENTRY) {
    networkDeviceCiscoSpectrumEntryInsert(action.id, action.entryAttrs).then(
      () => store.dispatch(fetchSpectrumTable(action.id))
    );
  }

  if(action.type == REMOVE_SPECTRUM_ENTRY) {
    networkDeviceCiscoSpectrumEntryRemove(action.id, action.entryId).then(
      () => store.dispatch(fetchSpectrumTable(action.id)),
    );
  }

  if(action.type === START_SPECTRUM_ENTRY) {
    networkDeviceCiscoSpectrumEntryStart(action.id, action.entryId).then(
      () => store.dispatch(fetchSpectrumTable(action.id)),
      (rej) => console.error(rej)
    )
  }

  if(action.type === STOP_SPECTRUM_ENTRY) {
    networkDeviceCiscoSpectrumEntryStop(action.id, action.entryId).then(
      () => store.dispatch(fetchSpectrumTable(action.id)),
      (rej) => console.error(rej)
    )
  }

  return next(action);
}

export default service;